import SignInPage from 'views/SignInPage';
import SignUpPage from 'views/SignUpPage';

const Routes = [
  {
    path: '/sign-up',
    name: 'SIGN-UP-PAGE',
    component: SignUpPage,
    exact: true
  },
  // {
  //   path: '/forgot-password',
  //   name: 'FORGOT-PASSWORD-PAGE',
  //   component: ForgotPasswordPage,
  //   exact: true
  // },
  // {
  //   path: '/reset-password',
  //   name: 'RESET-PASSWORD-PAGE',
  //   component: ResetPasswordPage,
  //   exact: true
  // },
  {
    path: '/sign-in',
    name: 'SIGN-IN-PAGE',
    component: SignInPage,
    exact: true
  }
];

export default Routes;
