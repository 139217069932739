import React, { useEffect, useContext } from 'react';
import queryString from 'query-string';

import config from 'config';

import { AuthContext } from 'AuthContext';

import Helmet from 'components/Helmet';
import LoadingScreen from 'components/LoadingScreen';

const RootPage = ({ history, location }) => {
  const [currentUser] = useContext(AuthContext);

  useEffect(() => {
    if (currentUser && currentUser.access_token && currentUser.isValidated) {
      const params = queryString.parse(location.search);
      if (params.redirect) {
        const splitRedirect = decodeURI(params.redirect).split('?');
        const redirectParams =
          splitRedirect && splitRedirect[1]
            ? queryString.parse(`?${splitRedirect[1]}`)
            : {};
        const newQueryString = queryString.stringify({
          ...redirectParams,
          token:
            params.redirectType && params.redirectType === 'authorization'
              ? currentUser.access_token
              : undefined
        });

        window.location.replace(
          `${splitRedirect[0]}${newQueryString ? `?${newQueryString}` : ''}`
        );
      } else history.replace(`${config.auth.homepage}`);
    } else history.replace(`${config.auth.login}${location.search}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet title={'LANG_PAGE_TITLE.LOADING'} />
      <LoadingScreen />
    </>
  );
};

export default RootPage;
