export const config = {
  appName: process.env.REACT_APP_PAGE_TITLE,
  lang: process.env.REACT_APP_LANG || 'en-us',
  theme: process.env.REACT_APP_THEME || 'classic',
  connect: {
    host: {
      bms: process.env.REACT_APP_BMS_URI,
      sso: process.env.REACT_APP_SSO_URI,
      master: process.env.REACT_APP_MASTER_URI
    },
    direction: {
      bms: '/api',
      sso: '/api',
      master: ''
    }
  },
  auth: {
    tokenCheckInterval: process.env.REACT_APP_TOKEN_CHECK_INTERVAL,
    login: '/sign-in',
    homepage: '/select-module',
    logout: '/sign-out'
  },
  modules: [
    { name: 'Bio', value: '/bio/', privileges: [1, 400], theme: 'bio' },
    {
      name: 'Checker',
      value: '/checker/',
      privileges: [1, 402],
      theme: 'checker'
    },
    {
      name: 'Reports',
      value: '/reports/',
      privileges: [1, 403],
      theme: 'reports'
    },
    {
      name: 'Viewer',
      value: '/viewer/',
      privileges: [1, 401],
      theme: 'viewer'
    },
    {
      name: 'Small viewer',
      value: '/small-viewer/',
      privileges: [1, 404],
      theme: 'smallViewer'
    },
    {
      name: 'Old viewer',
      value: '/old-viewer/',
      privileges: [1, 401],
      theme: 'viewer'
    },
    {
      name: 'Visits',
      value: '/visits/',
      privileges: [1, 405],
      theme: 'visits'
    },
    {
      name: 'Hyper',
      value: `/hyper/`,
      privileges: [1, 406],
      theme: 'hyper',
      isExternal: false
    }
  ]
};
export default config;
