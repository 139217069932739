import { css } from 'styled-components';
import { Media } from '../Mixins.style';

export const Section = css`
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
  min-height: 100%;
  height: auto;
`;

export const Wrapper = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 25px;

  ${Media.md`
    display: ${props => (props.hideMedium ? 'none' : 'flex')};
  `}
`;

export const Content = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const Navigation = css`
  position: absolute;
  top: 1%;
  right: 1%;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  padding: 5px;
  z-index: 1;

  ${Media.md`
    position: relative;
    display: block;
    text-align: center;
    z-index: initial;
    top: 0;
    right: 0;
  `}
`;

export const Imagebox = css`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  overflow: hidden;
`;

export const Image = css`
  position: absolute;
  top: -5%;
  left: 0;
  width: 120%;
  height: 150%;
`;
