import styled from 'styled-components';

import { Icon as commonIcon } from 'assets/Common/Icons.style';

import {
  Tooltip as commonTooltip,
  TooltipBox as commonTooltipBox,
  TooltipWrapper as commonTooltipWrapper
} from 'assets/Common/Tooltip.style';

export const TooltipBox = styled.div`
  ${commonTooltipBox}
`;

export const Icon = styled.i`
  ${commonIcon}
  top: inherit !important;
  left: inherit !important;
  right: inherit !important;
  transform: translateY(0);
`;

export const Tooltip = styled.div`
  ${commonTooltip}
`;

export const TooltipWrapper = styled.div`
  ${commonTooltipWrapper}
`;
