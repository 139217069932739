import React, { createContext, useState } from 'react';
import { IntlProvider } from 'react-intl';
import translations from 'translations';
import config from 'config';

const Context = createContext();

const IntlProviderWrapper = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    let restoreUser = localStorage.getItem('BMS_USER');
    restoreUser = restoreUser && JSON.parse(restoreUser);
    return restoreUser && restoreUser.lang ? restoreUser.lang : config.lang;
  });

  return (
    <Context.Provider value={{ switchLanguage: setLanguage }}>
      <IntlProvider
        key={
          translations[language]
            ? language
            : translations[language.split('-')[0]]
            ? language.split('-')[0]
            : config.lang
        }
        locale={
          translations[language]
            ? language
            : translations[language.split('-')[0]]
            ? language.split('-')[0]
            : config.lang
        }
        messages={
          translations[language]
            ? translations[language]
            : translations[language.split('-')[0]]
            ? translations[language.split('-')[0]]
            : translations[config.lang]
        }
        defaultLocale={config.lang}
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export { IntlProviderWrapper, Context as IntlContext };
