import { css } from 'styled-components';
import { Media } from '../Mixins.style';

export const SelectBox = css`
  position: relative;
  display: block;
  width: 100%;
`;

export const Control = css`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  height: 65px;
  border: 0;
  border-radius: 10px;
  background: transparent;
  outline: 0;
  transition: 0.2s all;
  box-sizing: border-box;

  ${Media.xxlg`
    height: 55px;
  `}

  [data-small] & {
    height: 38px;

    ${Media.xxlg`
      height: 30px;
    `}
  }
`;

export const SelectContainer = css`
  position: relative;
  display: block;
  width: 100%;
  background: ${props => props.theme.background};
  border: 0;
  border-radius: 10px;
  box-sizing: border-box;

  &::before {
    content: attr(data-name);
    position: absolute;
    top: 50%;
    left: 0;
    padding: 0 0 0 65px;
    color: ${props => props.theme.color};
    transition: 0.2s all;
    font-size: 18px;
    line-height: 1.2;
    font-weight: 400;
    opacity: 0;
    transform: translateY(-50%);

    ${Media.xxlg`
      padding: 0 0 0 55px;
      font-size: 16px;
    `}
  }

  &[data-filled]::before {
    top: 5px;
    font-size: 8px;
    opacity: 1;
    transform: translateY(0);

    ${Media.xxlg`
      top: 3px;
    `}
  }

  &[data-small]::before {
    padding: 0 0 0 40px;
    font-size: 16px;

    ${Media.xxlg`
      padding: 0 0 0 32px;
      font-size: 14px;
    `}
  }

  &[data-small][data-filled]::before {
    top: 2px;
    font-size: 8px;
    opacity: 1;
    transform: translateY(0);

    ${Media.xxlg`
      top: 0px;
    `}
  }

  &[data-focused] {
    box-shadow: 0px 4px 4px 0px ${props => props.theme.shadow};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const ValueContainer = css`
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding: 0;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
`;

export const Placeholder = css`
  display: flex;
  position: absolute;
  top: 50%;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 0 0 65px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${props => props.theme.color};
  transform: translateY(-50%);
  font-size: 22px;
  line-height: 1.2;
  font-weight: 400;
  box-sizing: border-box;

  ${Media.xxlg`
    padding: 0 0 0 55px;
    font-size: 20px;
  `}

  [data-small] & {
    padding: 0 0 0 40px;
    font-size: 18px;

    ${Media.xxlg`
      padding: 0 0 0 32px;
      font-size: 16px;
    `}
  }
`;

export const SingleValue = css`
  display: flex;
  position: absolute;
  top: 50%;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 0 0 65px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${props => props.theme.color};
  transform: translateY(-50%);
  font-size: 22px;
  line-height: 1.2;
  font-weight: 400;
  box-sizing: border-box;

  ${Media.xxlg`
    padding: 0 0 0 55px;
    font-size: 20px;
  `}

  [data-small] & {
    padding: 0 0 0 40px;
    font-size: 18px;

    ${Media.xxlg`
      padding: 0 0 0 32px;
      font-size: 16px;
    `}
  }
`;

export const Menu = css`
  position: absolute;
  top: 100%;
  width: 100%;
  border: 0;
  margin-top: 0px;
  z-index: 100;
`;

export const MenuList = css`
  position: relative;
  background: ${props => props.theme.background};
  box-shadow: 0px 4px 4px 0px ${props => props.theme.shadow};
  border-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  max-height: 130px;
  overflow: auto;

  ${Media.xxlg`
    max-height: 110px;
  `}

  [data-small] & {
    max-height: 152px;

    ${Media.xxlg`
      max-height: 120px;
    `}
  }

  &::-webkit-scrollbar {
    width: 9px;
    height: 9px;

    ${Media.xxlg`
      width: 7px;
      height: 7px;
    `}

    ${Media.md`
      width: 9px;
      height: 9px;
    `}

    ${Media.sm`
      width: 7px;
      height: 7px;
    `}

    [data-small] & {
      width: 5px;
      height: 5px;

      ${Media.xxlg`
        width: 4px;
        height: 4px;
      `}

      ${Media.md`
        width: 5px;
        height: 5px;
      `}

      ${Media.sm`
        width: 4px;
        height: 4px;
      `}
    }
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.thumb};
    border-radius: 9px;

    ${Media.xxlg`
      border-radius: 7px;
    `}

    ${Media.md`
      border-radius: 9px;
    `}

    ${Media.sm`
      border-radius: 7px;
    `}

    [data-small] & {
      border-radius: 5px;

      ${Media.xxlg`
        border-radius: 4px;
      `}

      ${Media.md`
        border-radius: 5px;
      `}

      ${Media.sm`
        border-radius: 4px;
      `}
    }
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.track};
  }
`;

export const Option = css`
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 65px;
  padding: 0 15px;
  color: ${props => props.theme.color};
  transition: 0.2s all;
  font-size: 22px;
  line-height: 1.2;
  font-weight: 400;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  ${Media.xxlg`
    height: 55px;
    padding: 0 13px;
    font-size: 20px;
  `}

  &:hover {
    background: ${props => props.theme.backgroundOnHover};
  }

  &[data-selected] {
    background: ${props => props.theme.backgroundOnSelect};
    font-weight: 500;
  }

  [data-small] & {
    height: 38px;
    padding: 0 0 0 13px;
    font-size: 18px;

    ${Media.xxlg`
      height: 30px;
      padding: 0 0 0 10px;
      font-size: 16px;
    `}
  }
`;

export const IndicatorSeparator = css``;

export const IndicatorsContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  transition: 0.2s all;
  box-sizing: border-box;
  cursor: pointer;

  ${Media.xxlg`
    width: 55px;
    height: 55px;
  `}

  [data-small] & {
    width: 38px;
    height: 38px;

    ${Media.xxlg`
      width: 30px;
      height: 30px;
    `}
  }
`;

export const NoOptionsMessage = css`
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 65px;
  padding: 0 0 0 15px;
  color: ${props => props.theme.color};
  transition: 0.2s all;
  font-size: 22px;
  line-height: 1.2;
  font-weight: 400;
  box-sizing: border-box;
  cursor: default;

  ${Media.xxlg`
    height: 55px;
    padding: 0 0 0 13px;
    font-size: 20px;
  `}

  [data-small] & {
    height: 38px;
    padding: 0 0 0 13px;
    font-size: 18px;

    ${Media.xxlg`
      height: 30px;
      padding: 0 0 0 10px;
      font-size: 16px;
    `}
  }
`;

export const SelectIcon = css`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  transition: 0.2s all;
  font-size: 28px;
  user-select: none;

  ${Media.xxlg`
    width: 55px;
    height: 55px;
    font-size: 24px;
  `}

  [data-small] & {
    width: 38px;
    height: 38px;
    font-size: 18px;

    ${Media.xxlg`
      width: 30px;
      height: 30px;
      font-size: 16px;
    `}
  }
`;

export const DropdownIndicator = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  transition: 0.2s all;
  font-size: 22px;

  ${Media.xxlg`
    width: 30px;
    height: 30px;
    font-size: 20px;
  `}

  [data-small] & {
    width: 38px;
    height: 38px;
    font-size: 18px;

    ${Media.xxlg`
      width: 30px;
      height: 30px;
      font-size: 16px;
    `}
  }
`;

export const DropdownIndicatorIcon = css`
  color: ${props => props.theme.color};
  transform: scaleY(1);
  transition: 0.2s transform;
  user-select: none;
  font-size: 28px;

  ${Media.xxlg`
    font-size: 26px;
  `}

  [data-small] & {
    font-size: 24px;

    ${Media.xxlg`
      font-size: 22px;
    `}
  }

  &[data-focused] {
    transform: scaleY(-1);
  }
`;
