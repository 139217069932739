import React from 'react';
import useWindowSize from '@rehooks/window-size';

import IMG_BMS_ICON from 'assets/Images/SIFD_Orange_icon_BMS.svg';

import Helmet from 'components/Helmet';
import Logo from 'components/Logo';
import Logout from 'components/Logout';
import SelectModulePanel from 'components/SelectModulePanel';

import {
  Section,
  Wrapper,
  Content,
  Navigation,
  Imagebox,
  Image
} from './SelectModulePage.style';
import { Breakpoints } from 'assets/Variables.style';

const SelectModulePage = () => {
  let windowSize = useWindowSize();

  return (
    <>
      <Helmet title={'LANG_PAGE_TITLE.SELECT_MODULE'} />
      <Section>
        {windowSize.innerWidth > Breakpoints.md && (
          <Navigation>
            <Logout />
          </Navigation>
        )}
        <Wrapper>
          <Content>
            <Logo />
            <SelectModulePanel />
            {windowSize.innerWidth <= Breakpoints.md && (
              <Navigation>
                <Logout />
              </Navigation>
            )}
          </Content>
        </Wrapper>
        <Wrapper hideMedium>
          <Imagebox>
            <Image src={IMG_BMS_ICON} alt='BMS' />
          </Imagebox>
        </Wrapper>
      </Section>
    </>
  );
};

export default SelectModulePage;
