import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from 'ThemeContext';
import config from 'config';

import { Link } from './Logout.style';

const Logout = () => {
  const theme = useTheme();

  return (
    <Link to={config.auth.logout} theme={theme.link}>
      <FormattedMessage id='LOGOUT.LOGOUT' />
    </Link>
  );
};

export default Logout;
