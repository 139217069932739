import styled from 'styled-components';

import {
  Panel as commonPanel,
  Title as commonTitle,
  TitleDeco as commonTitleDeco,
  Buttonbox as commonButtonbox,
  Contentbox as commonContentbox
} from 'assets/Common/SignPanel.style';

import { Button as commonButton } from 'assets/Common/Button.style';

export const Panel = styled.div`
  ${commonPanel}
`;

export const Title = styled.h1`
  ${commonTitle}
`;

export const TitleDeco = styled.span`
  ${commonTitleDeco}
`;

export const Contentbox = styled.div`
  ${commonContentbox}
`;

export const Buttonbox = styled.div`
  ${commonButtonbox}
`;

export const Button = styled.button`
  ${commonButton}
`;
