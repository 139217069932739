import { css } from 'styled-components';
import { Media } from '../Mixins.style';

export const InputHelperBox = css`
  display: flex;
`;

export const InputBox = css`
  position: relative;
  display: block;
  width: 100%;
  background: ${props => props.theme.background};
  border: 0;
  border-radius: 10px;

  &::before {
    content: attr(data-name);
    position: absolute;
    top: 50%;
    left: 0;
    padding: 0 0 0 65px;
    color: ${props => props.theme.color};
    transition: 0.2s all;
    font-size: 18px;
    line-height: 1.2;
    font-weight: 400;
    opacity: 0;
    transform: translateY(-50%);

    ${Media.xxlg`
      padding: 0 0 0 55px;
      font-size: 16px;
    `}
  }

  &[data-filled]::before {
    top: 5px;
    font-size: 8px;
    opacity: 1;
    transform: translateY(0);

    ${Media.xxlg`
      top: 3px;
    `}
  }
`;

export const Input = css`
  position: relative;
  display: block;
  width: 100%;
  height: 70px;
  padding: 0 0 0 65px;
  border: 2px solid transparent;
  border-radius: 10px;
  color: ${props => props.theme.color};
  background: transparent;
  outline: 0;
  transition: 0.2s all;
  font-size: 22px;
  line-height: 1.2;
  font-weight: 400;
  box-sizing: border-box;
  text-shadow: 0px 3px 6px ${props => props.theme.shadow};

  ${Media.xxlg`
    height: 65px;
    padding: 0 0 0 55px;
    font-size: 20px;
  `}
    ::-webkit-input-placeholder {
    text-shadow: none;
    color: ${props => props.theme.placeholderColor};
  }

  &:focus {
    border-bottom: 2px solid ${props => props.theme.borderOnFocus};
  }

  &[data-invalid] {
    color: ${props => props.theme.colorOnInvalid};
    border: 2px solid ${props => props.theme.borderOnInvalid};
    box-shadow: 0px 0px 5px 0px ${props => props.theme.borderOnInvalid};
  }

  &[data-valid] {
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    border-bottom: 2px solid ${props => props.theme.borderOnValid};
    border-left: 2px solid transparent;
  }

  &[disabled] {
    opacity: 0.7;
  }
`;

export const InputIcon = css`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  transition: 0.2s all;
  color: ${props => props.theme.placeholderColor};
  font-size: 24px;
  z-index: 1;
  user-select: none;

  ${Media.xxlg`
    width: 65px;
    height: 65px;
    font-size: 22px;
  `}

  &[data-filled] {
    color: ${props => props.theme.color};
  }
`;

export const InputSign = css`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  transition: 0.2s all;
  color: ${props => props.theme.color};
  font-size: 24px;
  cursor: pointer;
  user-select: none;

  ${Media.xxlg`
    width: 65px;
    height: 65px;
    font-size: 22px;
  `}
`;
