import styled from 'styled-components';
import { Media } from 'assets/Mixins.style';

export const Box = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${Media.md`
    justify-content: center;
  `}
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 96px;
  margin: 5px;
  padding: 15px;
  border-radius: 100%;
  box-shadow: 2px 2px 4px 0px ${props => props.theme.shadow};
  background: ${props => props.theme.primaryBackground};
  background-image: ${props =>
    `linear-gradient(180deg, ${props.theme.primaryBackground}, ${props.theme.secondaryBackground})`};
  color: ${props => props.theme.color};
  transition: 0.2s all;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;

  &:hover {
    transform: scale(1.11, 1.11);
    background: ${props => props.theme.secondaryBackground};
    background-image: ${props =>
      `linear-gradient(165deg, ${props.theme.primaryBackground}, ${props.theme.secondaryBackground})`};
  }
`;
