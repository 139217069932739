import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import queryString from 'query-string';

import config from 'config';
import {
  validateUser,
  refreshUserToken,
  requestProfile,
  checkNeedNewUserToken
} from 'requestAPI';

import { AuthContext } from 'AuthContext';
import { IntlContext } from 'IntlContext';
import { ThemeContext } from 'ThemeContext';

import Helmet from 'components/Helmet';
import LoadingScreen from 'components/LoadingScreen';

import Routes from 'routes';

import 'react-toastify/dist/ReactToastify.css';

toast.configure({
  hideProgressBar: true,
  position: 'bottom-right'
});

const System = ({ history, location }) => {
  const intl = useIntl();
  const { switchLanguage } = useContext(IntlContext);
  const { theme, switchTheme } = useContext(ThemeContext);
  const [currentUser, userDispatch] = useContext(AuthContext);
  const [requestRefreshTokenLoading, setRequestRefreshTokenLoading] = useState(
    false
  );

  const requestRefreshToken = async () => {
    const res = await refreshUserToken({
      exp: currentUser.exp,
      access_token: currentUser.access_token
    });
    if (res) {
      if (!res.error && res.access_token) {
        userDispatch({ type: 'SET_NEW_TOKEN', payload: res });
        return true;
      } else if (res.error && !res.offline) userDispatch({ type: 'LOGOUT' });
    }

    return false;
  };

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    if (queryParams.token) {
      const newQueryParams = queryString.stringify({
        ...queryParams,
        token: undefined
      });
      history.replace(`${location.pathname}?${newQueryParams}`);
    }
    (async () => {
      if (
        queryParams.token ||
        (currentUser &&
          currentUser.access_token &&
          currentUser.isValidated === undefined)
      ) {
        const res = await validateUser({
          access_token: queryParams.token
            ? queryParams.token
            : currentUser.access_token
        });
        if (res) {
          if (!res.error || res.offline) {
            if (queryParams.token)
              userDispatch({
                type: 'SET_NEW_TOKEN',
                payload: { ...res, access_token: queryParams.token }
              });
            else userDispatch({ type: 'SET_TOKEN_VALIDATE', payload: true });
            requestRefreshToken();
          } else userDispatch({ type: 'LOGOUT' });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let interval;
    if (currentUser && currentUser.access_token && currentUser.isValidated) {
      interval = setInterval(async () => {
        if (checkNeedNewUserToken({ exp: currentUser.exp }))
          if (!requestRefreshTokenLoading) {
            setRequestRefreshTokenLoading(true);
            await requestRefreshToken();
            setRequestRefreshTokenLoading(false);
          }
      }, parseInt(config.auth.tokenCheckInterval) * 1000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    requestRefreshTokenLoading,
    currentUser && currentUser.access_token,
    currentUser && currentUser.isValidated
  ]);

  useEffect(() => {
    const controller = new AbortController();
    if (
      currentUser &&
      currentUser.access_token &&
      currentUser.isValidated &&
      !currentUser.isLoaded
    ) {
      (async () => {
        const res = await requestProfile({
          access_token: currentUser.access_token,
          signal: controller.signal
        });
        if (res && !res.error) {
          if (
            String(Object.values(res.users)[0].UUID) ===
            String(currentUser.uuid)
          )
            userDispatch({
              type: 'SET_USER',
              payload: Object.values(res.users)[0]
            });
          else userDispatch({ type: 'LOGOUT' });
        } else if (!res.abort) userDispatch({ type: 'LOGOUT' });
      })();
    }
    return () => controller.abort();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser && currentUser.isValidated]);

  useEffect(() => {
    if (currentUser.lang && currentUser.lang !== intl.locale)
      switchLanguage(currentUser.lang);
    else if (!currentUser.lang && config.lang !== intl.locale)
      switchLanguage(config.lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser && currentUser.lang]);

  useEffect(() => {
    if (currentUser.theme && currentUser.theme !== theme)
      switchTheme(currentUser.theme);
    else if (!currentUser.lang && config.theme !== theme)
      switchTheme(config.theme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser && currentUser.theme]);

  if (
    currentUser &&
    currentUser.access_token &&
    (currentUser.isValidated === undefined ||
      currentUser.isLoaded === undefined)
  )
    return (
      <>
        <Helmet title={'LANG_PAGE_TITLE.LOADING'} />
        <LoadingScreen />
      </>
    );

  return (
    <Switch>
      {currentUser && currentUser.access_token && currentUser.isValidated
        ? Routes.AUTH_ROUTES.map(({ path, exact, name, component }) => {
            return (
              <Route
                key={name}
                path={path}
                exact={exact}
                name={name}
                component={component}
              />
            );
          })
        : Routes.PUBLIC_ROUTES.map(({ path, exact, name, component }) => {
            return (
              <Route
                key={name}
                path={path}
                exact={exact}
                name={name}
                component={component}
              />
            );
          })}
      {Routes.OTHER_ROUTES.map(({ path, exact, name, component }) => {
        return (
          <Route
            key={name}
            path={path}
            exact={exact}
            name={name}
            component={component}
          />
        );
      })}
    </Switch>
  );
};

export default withRouter(System);
