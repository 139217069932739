import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from 'ThemeContext';
import {
  Button,
  Buttonbox,
  Error,
  Errorbox,
  ErrorIcon,
  Fieldbox,
  Formbox,
  Input,
  InputBox,
  InputIcon,
  InputSign,
  Panel,
  Title,
  TitleDeco
} from './SignInPanel.style';

const SignInPanel = ({ onSubmit = () => {} }) => {
  const intl = useIntl();
  const theme = useTheme();
  const [hidePassword, setHidePassword] = useState(true);

  const validate = values => {
    const errors = {};
    if (
      !values.username ||
      (values.username &&
        !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          values.username
        ))
    )
      errors.username = true;
    if (!values.password) errors.password = true;
    return errors;
  };
  return (
    <Panel>
      <Formbox>
        <Title theme={theme.title}>
          <FormattedMessage id='SIGN_IN_PANEL.TITLE' />{' '}
          <TitleDeco theme={theme.titledeco}>BMS</TitleDeco>
        </Title>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, submitting, submitError, pristine }) => (
            <form onSubmit={handleSubmit}>
              {submitError && (
                <Errorbox>
                  <Error theme={theme.error}>
                    <ErrorIcon className='material-icons'>
                      info_outline
                    </ErrorIcon>
                    <span>{submitError}</span>
                  </Error>
                </Errorbox>
              )}
              <Fieldbox>
                <Field name='username'>
                  {({ input, meta }) => (
                    <InputBox theme={theme.inputBox}>
                      <InputIcon
                        theme={theme.inputIcon}
                        className='material-icons'
                        data-filled={input.value ? true : undefined}
                      >
                        mail_outline
                      </InputIcon>
                      <Input
                        {...input}
                        theme={theme.input}
                        type='email'
                        placeholder={intl.formatMessage({
                          id: `SIGN_IN_PANEL.EMAIL`
                        })}
                        autoComplete='on'
                        data-invalid={
                          meta.touched && meta.error ? true : undefined
                        }
                        data-valid={
                          meta.touched && !meta.error ? true : undefined
                        }
                      />
                    </InputBox>
                  )}
                </Field>
                <Field name='password'>
                  {({ input, meta }) => (
                    <InputBox theme={theme.inputBox}>
                      <InputIcon
                        theme={theme.inputIcon}
                        className='material-icons'
                        data-filled={input.value ? true : undefined}
                      >
                        lock_outline
                      </InputIcon>
                      <Input
                        {...input}
                        theme={theme.input}
                        type={hidePassword ? 'password' : 'text'}
                        placeholder={intl.formatMessage({
                          id: `SIGN_IN_PANEL.PASSWORD`
                        })}
                        autoComplete='on'
                        data-invalid={
                          meta.touched && meta.error ? true : undefined
                        }
                        data-valid={
                          meta.touched && !meta.error ? true : undefined
                        }
                      />
                      <InputSign
                        theme={theme.inputsign}
                        className='material-icons-outlined'
                        onClick={() => {
                          setHidePassword(!hidePassword);
                        }}
                      >
                        {hidePassword ? 'visibility_off' : 'visibility'}
                      </InputSign>
                    </InputBox>
                  )}
                </Field>
              </Fieldbox>
              {/* <Linkbox>
                <Link theme={theme.link} to={`/reset-password`}>
                  <FormattedMessage id='SIGN_IN_PANEL.FORGOT_YOUR_PASSWORD' />
                </Link>
              </Linkbox> */}
              <Buttonbox>
                <Button theme={theme.button} disabled={submitting || pristine}>
                  {submitting ? (
                    <FormattedMessage id='SIGN_IN_PANEL.PLEASE_WAIT' />
                  ) : (
                    <FormattedMessage id='SIGN_IN_PANEL.SIGN_IN' />
                  )}
                </Button>
              </Buttonbox>
            </form>
          )}
        />
      </Formbox>
    </Panel>
  );
};

SignInPanel.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  error: PropTypes.string,
  onSubmit: PropTypes.func
};

export default SignInPanel;
