import styled from 'styled-components';
import { Media } from 'assets/Mixins.style';

import {
  SelectBox as commonSelectBox,
  Control as commonControl,
  ValueContainer as commonValueContainer,
  SelectContainer as commonSelectContainer,
  Placeholder as commonPlaceholder,
  SingleValue as commonSingleValue,
  Menu as commonMenu,
  MenuList as commonMenuList,
  Option as commonOption,
  IndicatorSeparator as commonIndicatorSeparator,
  IndicatorsContainer as commonIndicatorsContainer,
  NoOptionsMessage as commonNoOptionsMessage,
  SelectIcon as commonSelectIcon,
  DropdownIndicator as commonDropdownIndicator,
  DropdownIndicatorIcon as commonDropdownIndicatorIcon
} from 'assets/Common/Select.style';

export const SelectBox = styled.div`
  ${commonSelectBox}
  width: 160px;

  ${Media.xxlg`
    width: 140px;
  `}
`;

export const Control = styled.div`
  ${commonControl}
`;

export const ValueContainer = styled.div`
  ${commonValueContainer}
`;

export const SelectContainer = styled.div`
  ${commonSelectContainer}
`;

export const Placeholder = styled.div`
  ${commonPlaceholder}
`;

export const SingleValue = styled.div`
  ${commonSingleValue}
`;

export const Menu = styled.div`
  ${commonMenu}
`;

export const MenuList = styled.div`
  ${commonMenuList}
`;

export const Option = styled.div`
  ${commonOption}
`;

export const IndicatorSeparator = styled.div`
  ${commonIndicatorSeparator}
`;

export const IndicatorsContainer = styled.div`
  ${commonIndicatorsContainer}
`;

export const NoOptionsMessage = styled.div`
  ${commonNoOptionsMessage}
`;

export const SelectIcon = styled.i`
  ${commonSelectIcon}
`;

export const DropdownIndicator = styled.div`
  ${commonDropdownIndicator}
`;

export const DropdownIndicatorIcon = styled.i`
  ${commonDropdownIndicatorIcon}
`;
