import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { Media } from 'assets/Mixins.style';

export const Link = styled(RouterLink)`
  position: absolute;
  top: 1%;
  left: 1%;

  ${Media.md`
    position: relative;
  `}
`;

export const Image = styled.img`
  display: block;
  width: 150px;
  height: auto;
`;
