import { css } from 'styled-components';
import { Media } from '../Mixins.style';

export const Link = css`
  display: inline-flex;
  align-items: center;
  transition: 0.2s all;
  border: 0;
  background: transparent;
  color: ${props => props.theme.color};
  font-size: 19px;
  line-height: 1.6;
  font-weight: 400;
  user-select: none;
  cursor: pointer;

  ${Media.xxlg`
    font-size: 16px;
  `}

  &:hover {
    color: ${props => props.theme.colorOnHover};
  }
`;

export const LinkUnderline = css`
  position: relative;
  display: inline-block;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 0;
    border-bottom: 2px dotted ${props => props.theme.underlineColor};
    content: '';
  }
`;
