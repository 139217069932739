import React from 'react';
import { useIntl } from 'react-intl';
import IMG_BMS from 'assets/Images/SIFD_Orange_BMS.svg';

import { Link, Image } from './Logo.style';

const Logo = () => {
  const intl = useIntl();
  return (
    <Link
      to='/'
      title={intl.formatMessage({
        id: `LOGO.GO_TO_HOME`
      })}
    >
      <Image src={IMG_BMS} alt='BMS' />
    </Link>
  );
};

export default Logo;
