import React from 'react';
import useWindowSize from '@rehooks/window-size';

import IMG_BMS_ICON from 'assets/Images/SIFD_Orange_icon_BMS.svg';

import Helmet from 'components/Helmet';
import SignUpPanel from 'components/SignUpPanel';
import Logo from 'components/Logo';
import SwitchLanguage from 'components/SwitchLanguage';

import {
  Section,
  Wrapper,
  Content,
  Navigation,
  Imagebox,
  Image
} from './SignUpPage.style';
import { Breakpoints } from 'assets/Variables.style';

const SignUpPage = ({ history }) => {
  let windowSize = useWindowSize();

  const handleSubmit = () => {
    //TODO: Podpiąć sign up
    console.log('on submit');
  };

  return (
    <>
      <Helmet title={'LANG_PAGE_TITLE.SIGN_UP'} />
      <Section>
        {windowSize.innerWidth > Breakpoints.md && (
          <Navigation>
            <SwitchLanguage />
          </Navigation>
        )}
        <Wrapper>
          <Content>
            <Logo />
            <SignUpPanel onSubmit={handleSubmit} />
            {windowSize.innerWidth <= Breakpoints.md && (
              <Navigation>
                <SwitchLanguage />
              </Navigation>
            )}
          </Content>
        </Wrapper>
        <Wrapper hideMedium>
          <Imagebox>
            <Image src={IMG_BMS_ICON} alt='BMS' />
          </Imagebox>
        </Wrapper>
      </Section>
    </>
  );
};

export default SignUpPage;
