import config from 'config';

const BMS_URL = `${config.connect.host.bms}${config.connect.direction.bms}`;
const SSO_URL = `${config.connect.host.sso}${config.connect.direction.sso}`;

// Sign in user
export const signInUser = ({ credential }) => {
  return fetch(`${SSO_URL}/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credential)
  })
    .then(res => {
      if (res.ok) return res.json();
      throw new Error();
    })
    .then(res => res)
    .catch(() => {
      return { error: true, message: 'ERROR.LOGIN' };
    });
};

// Validate user
export const validateUser = ({ access_token }) => {
  return fetch(`${SSO_URL}/auth/validate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ token: access_token })
  })
    .then(res => {
      if (res.ok) return res.json();
      throw new Error();
    })
    .then(res => res)
    .catch(() => {
      return { error: true, message: 'ERROR.VALIDATE' };
    });
};

// Sign out user
export const signOutUser = async ({ access_token }) => {
  let res = await receiveRefreshUserToken({ access_token });
  if (res && !res.error)
    res = await requestSignOutUser({
      refresh_token: res.refresh_token
    });
  return res;
};

// Request sign out user
const requestSignOutUser = ({ refresh_token }) => {
  return fetch(`${SSO_URL}/auth/logout`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ token: refresh_token })
  })
    .then(res => res.ok)
    .then(() => {
      return { error: false };
    })
    .catch(() => {
      return { error: true, message: 'ERROR.LOGOUT' };
    });
};

// Refresh user token
export const refreshUserToken = async ({ exp, access_token }) => {
  if (checkNeedNewUserToken({ exp })) {
    let res = await receiveRefreshUserToken({
      access_token
    });
    if (res && !res.error) {
      res = await refreshAccessUserToken({
        refresh_token: res.refresh_token
      });
    }
    return res;
  }
  return { error: false };
};

// Check need new user token
export const checkNeedNewUserToken = ({ exp }) => {
  const now = Math.round(Date.now() / 1000);
  if (now >= exp - 600) return true;
  return false;
};

// User receive refresh token
const receiveRefreshUserToken = ({ access_token }) => {
  return fetch(`${SSO_URL}/auth/receiveRefreshToken`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ token: access_token })
  })
    .then(res => {
      if (res.ok) return res.json();
      throw new Error();
    })
    .then(res => res)
    .catch(() => {
      return { error: true, message: 'ERROR.RECEIVE_REFRESH_TOKEN' };
    });
};

// Refresh access user token
const refreshAccessUserToken = ({ refresh_token }) => {
  return fetch(`${SSO_URL}/auth/refreshAccessToken`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ token: refresh_token })
  })
    .then(res => {
      if (res.ok) return res.json();
      throw new Error();
    })
    .then(res => res)
    .catch(() => {
      return { error: true, message: 'ERROR.REFRESH_ACCESS_TOKEN' };
    });
};

// Request profile
export const requestProfile = ({ access_token, signal }) => {
  return fetch(`${BMS_URL}/me`, {
    signal,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `JWT ${access_token}`
    }
  })
    .then(res => {
      if (res.ok) return res.json();
      throw new Error();
    })
    .then(res => res)
    .catch(err => {
      if (err.name === 'AbortError') return { error: true, abort: true };
      return {
        error: true,
        message: 'ERROR.REQUEST_PROFILE'
      };
    });
};
