import styled from 'styled-components';
import { Media } from 'assets/Mixins.style';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.primaryBackground};
  background-image: ${props =>
    `linear-gradient(to bottom, ${props.theme.primaryBackground}, ${props.theme.secondaryBackground})`};
  color: ${props => props.theme.color};
  padding: 45px 20px;
  text-align: center;
  cursor: default;

  ${Media.xxlg`
    padding: 35px 20px;
  `}

  ${Media.md`
    padding: 55px 20px;
  `}

  ${Media.sm`
    padding: 45px 20px;
  `}
`;

export const Content = styled.div`
  display: inline-block;
`;

export const Icon = styled.img`
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 auto 10px;

  ${Media.xxlg`
    width: 36px;
    height: 36px;
  `}

  ${Media.md`
    width: 44px;
    height: 44px;
  `}

  ${Media.sm`
    width: 40px;
    height: 40px;
  `}
`;

export const Text = styled.div`
  font-size: 22px;
  line-height: 1.2;
  font-weight: 400;

  ${Media.xxlg`
    font-size: 18px;
  `}

  ${Media.md`
    font-size: 24px;
  `}

  ${Media.sm`
    font-size: 22px;
  `}
`;
