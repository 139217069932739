import React from 'react';
import { useIntl } from 'react-intl';
import { Helmet as HelmetContext } from 'react-helmet-async';
import config from 'config';

export const Helmet = ({ title }) => {
  const intl = useIntl();
  return (
    <HelmetContext titleTemplate={`%s | ${config.appName}`}>
      <html lang={intl.locale} />
      <title lang={intl.locale}>
        {intl.formatMessage({
          id: title
        })}
      </title>
    </HelmetContext>
  );
};

export default Helmet;
