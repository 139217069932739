import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { useTheme } from 'ThemeContext';
import { useIntl, FormattedMessage } from 'react-intl';

import InputTooltip from '../InputTooltip/InputTooltip';

import {
  Panel,
  Title,
  TitleDeco,
  Errorbox,
  Error,
  ErrorIcon,
  Formbox,
  Fieldbox,
  InputBox,
  Input,
  InputIcon,
  InputSign,
  Buttonbox,
  Button
} from './SignUpPanel.style';

const SignUpPanel = ({ onSubmit = () => {} }) => {
  const intl = useIntl();
  const theme = useTheme();
  const [hidePassword, setHidePassword] = useState(true);
  const [hideRepeatPassword, setHideRepeatPassword] = useState(true);

  const validate = values => {
    const errors = {};
    const passwordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?:[a-zA-Z0-9!@#$%^&*_]{8,})$/;

    if (!values.name) errors.name = true;
    if (!values.surname) errors.surname = true;
    if (
      !values.password ||
      (values.password && !passwordReg.test(values.password))
    )
      errors.password = true;
    if (
      !values.repeatpassword ||
      (values.repeatpassword && values.repeatpassword !== values.password)
    )
      errors.repeatpassword = true;

    return errors;
  };

  return (
    <Panel>
      <Formbox>
        <Title theme={theme.title}>
          <FormattedMessage id='SIGN_UP_PANEL.TITLE' />{' '}
          <TitleDeco theme={theme.titledeco}>BMS</TitleDeco>
        </Title>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, submitting, submitError, pristine }) => (
            <form onSubmit={handleSubmit}>
              <Errorbox>
                {submitError && (
                  <Error theme={theme.error}>
                    <ErrorIcon className='material-icons'>
                      info_outline
                    </ErrorIcon>{' '}
                    {submitError}
                  </Error>
                )}
              </Errorbox>
              <Fieldbox>
                <Field name='name'>
                  {({ input, meta }) => (
                    <InputBox theme={theme.inputBox}>
                      <InputIcon
                        theme={theme.inputIcon}
                        className='material-icons-outlined'
                        data-filled={input.value ? true : undefined}
                      >
                        person_outline
                      </InputIcon>
                      <Input
                        {...input}
                        theme={theme.input}
                        type='text'
                        placeholder={intl.formatMessage({
                          id: `SIGN_UP_PANEL.NAME`
                        })}
                        autoComplete='on'
                        data-invalid={
                          meta.touched && meta.error ? true : undefined
                        }
                        data-valid={
                          meta.touched && !meta.error ? true : undefined
                        }
                      />
                    </InputBox>
                  )}
                </Field>
                <Field name='surname'>
                  {({ input, meta }) => (
                    <InputBox theme={theme.inputBox}>
                      <InputIcon
                        theme={theme.inputIcon}
                        className='material-icons-outlined'
                        data-filled={input.value ? true : undefined}
                      >
                        people_alt
                      </InputIcon>
                      <Input
                        {...input}
                        theme={theme.input}
                        type='text'
                        placeholder={intl.formatMessage({
                          id: `SIGN_UP_PANEL.SURNAME`
                        })}
                        autoComplete='on'
                        data-invalid={
                          meta.touched && meta.error ? true : undefined
                        }
                        data-valid={
                          meta.touched && !meta.error ? true : undefined
                        }
                      />
                    </InputBox>
                  )}
                </Field>
                <Field name='password'>
                  {({ input, meta }) => (
                    <InputBox theme={theme.inputBox}>
                      <InputIcon
                        theme={theme.inputIcon}
                        className='material-icons'
                        data-filled={input.value ? true : undefined}
                      >
                        lock_outline
                      </InputIcon>
                      <Input
                        {...input}
                        theme={theme.input}
                        type={hidePassword ? 'password' : 'text'}
                        placeholder={intl.formatMessage({
                          id: `SIGN_UP_PANEL.PASSWORD`
                        })}
                        autoComplete='on'
                        data-invalid={
                          meta.touched && meta.error ? true : undefined
                        }
                        data-valid={
                          meta.touched && !meta.error ? true : undefined
                        }
                      />
                      <InputSign
                        theme={theme.inputsign}
                        className='material-icons-outlined'
                        onClick={() => {
                          setHidePassword(!hidePassword);
                        }}
                      >
                        {hidePassword ? 'visibility_off' : 'visibility'}
                      </InputSign>
                      <InputTooltip />
                    </InputBox>
                  )}
                </Field>
                <Field name='repeatpassword'>
                  {({ input, meta }) => (
                    <InputBox theme={theme.inputBox}>
                      <InputIcon
                        theme={theme.inputIcon}
                        className='material-icons'
                        data-filled={input.value ? true : undefined}
                      >
                        lock_outline
                      </InputIcon>
                      <Input
                        {...input}
                        theme={theme.input}
                        type={hideRepeatPassword ? 'password' : 'text'}
                        placeholder={intl.formatMessage({
                          id: `SIGN_UP_PANEL.REPEAT_PASSWORD`
                        })}
                        autoComplete='on'
                        data-invalid={
                          meta.touched && meta.error ? true : undefined
                        }
                        data-valid={
                          meta.touched && !meta.error ? true : undefined
                        }
                      />
                      <InputSign
                        theme={theme.inputsign}
                        className='material-icons-outlined'
                        onClick={() => {
                          setHideRepeatPassword(!hideRepeatPassword);
                        }}
                      >
                        {hideRepeatPassword ? 'visibility_off' : 'visibility'}
                      </InputSign>
                    </InputBox>
                  )}
                </Field>
              </Fieldbox>
              <Buttonbox>
                <Button theme={theme.button} disabled={submitting || pristine}>
                  {submitting ? (
                    <FormattedMessage id='SIGN_UP_PANEL.PLEASE_WAIT' />
                  ) : (
                    <FormattedMessage id='SIGN_UP_PANEL.SIGN_UP' />
                  )}
                </Button>
              </Buttonbox>
            </form>
          )}
        />
      </Formbox>
    </Panel>
  );
};

SignUpPanel.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  error: PropTypes.string,
  onSubmit: PropTypes.func
};

export default SignUpPanel;
