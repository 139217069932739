export const Breakpoints = {
  xs: 480,
  sm: 767,
  md: 969,
  lg: 1024,
  xlg: 1280,
  xxlg: 1440
};

export const Colors = {
  supernova: '#fdc80f',
  tanHide: '#f89f4b',
  doveGray: '#6D6D6D',
  mineShaft: '#383838',
  wildSand: '#f6f6f6',
  white: '#fff',
  whiteTwo: '#fefefe',
  black: '#000',
  delta: '#ababa7',
  red: '#ff0606',
  cornflowerBlue: '#5195FB',
  heliotrope: '#c154fe',
  astral: '#327da2',
  atoll: '#0b5376',
  salmon: '#FF875F',
  wildWatermelon: '#FF5C76',
  seaBuckthorn: '#F49A34',
  java: '#10B2B8',
  surfieGreen: '#10678E',
  mulberry: '#CD4684',
  gigas: '#473E96',
  ceriseRed: '#E23278',
  rouge: '#9C3C79'
};
