import React, { createContext, useReducer } from 'react';
export const Context = createContext();

const initAuthState = () => {
  const user = localStorage.getItem('BMS_USER');
  if (!user) return {};
  return JSON.parse(user);
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      return state;
    }
    case 'SIGN_IN': {
      const { exp, iat, sub, access_token, metadata } = action.payload;
      const user = {
        access_token,
        uuid: sub,
        iat,
        exp,
        companyCode: metadata && metadata.companyCode
      };
      localStorage.setItem('BMS_USER', JSON.stringify(user));
      return { ...user, isValidated: true };
    }
    case 'SET_NEW_TOKEN': {
      const { access_token, sub, iat, exp } = action.payload;
      const newUser = {
        ...state,
        access_token,
        uuid: sub || state.uuid,
        iat,
        exp
      };
      const storage = Object.assign({}, newUser);
      delete storage['email'];
      delete storage['privileges'];
      delete storage['points'];
      delete storage['history'];
      delete storage['tags'];
      delete storage['metadata'];
      delete storage['settings'];
      delete storage['isValidated'];
      delete storage['isLoaded'];
      localStorage.setItem('BMS_USER', JSON.stringify(storage));
      return { ...newUser, isValidated: true };
    }
    case 'SET_TOKEN_VALIDATE': {
      return { ...state, isValidated: action.payload };
    }
    case 'SET_USER': {
      const {
        email,
        language,
        qa_app_theme,
        companyRoles,
        customerRoles
      } = action.payload;
      const newUser = {
        ...state,
        email,
        lang: language,
        theme: qa_app_theme,
        companyCode: companyRoles && Object.values(companyRoles)[0].companyCode,
        privileges:
          companyRoles || customerRoles
            ? {
                companyRoles,
                customerRoles
              }
            : undefined
      };
      const storage = Object.assign({}, newUser);
      delete storage['email'];
      delete storage['privileges'];
      delete storage['points'];
      delete storage['history'];
      delete storage['tags'];
      delete storage['metadata'];
      delete storage['settings'];
      delete storage['isValidated'];
      delete storage['isLoaded'];
      localStorage.setItem('BMS_USER', JSON.stringify(storage));
      return { ...newUser, isValidated: true, isLoaded: true };
    }
    case 'LOGOUT': {
      localStorage.clear();
      return {};
    }
    default:
      return state;
  }
};

const AuthProviderWrapper = ({ children }) => (
  <Context.Provider value={useReducer(authReducer, initAuthState())}>
    {children}
  </Context.Provider>
);

export { AuthProviderWrapper, Context as AuthContext };
